import createStore from 'unistore'
import Cookies from 'js-cookie'

const user = Cookies.get('isLoggedIn') ? {} : undefined

export default createStore({
  user,
  registerView: 'register',
  allowUpdatingUserState: true
})
