export const A = 'a'
export const Article = 'article'
export const AnimatedDiv = 'div'
export const Div = 'div'
export const Em = 'em'
export const Figure = 'figure'
export const Form = 'form'
export const H1 = 'h1'
export const H2 = 'h2'
export const H3 = 'h3'
export const Img = 'img'
export const Label = 'label'
export const P = 'p'
export const Section = 'section'
export const Small = 'small'
export const Span = 'span'
export const ViewSpan = 'span'

export const Button = ({ children, type, ...props }) => (
  <button type={type || 'button'} {...props}>{children}</button>
)

export const TextInput = ({ onChangeText, type, ...props }) => (
  type === 'textarea'
    ? (
      <textarea
        onInput={e => onChangeText(e.currentTarget.value)}
        {...props}
      />
    )
    : (
      <input
        type={type || 'text'}
        onInput={e => onChangeText(e.currentTarget.value)}
        {...props}
      />
    )
)
