import styled from 'styled-components'
import { Wrapper, WrapperA, Text, Loading } from './ActionButton.style'
import Loader from './Loader'

const WebWrapper = styled(Wrapper)`
  transition: background .1s ease, color .1s ease;

  &:hover, &:focus {
    background: #${p => p.secondary ? '594b6b' : p.white ? 'fff' : 'ff9187'};
    outline: none;
  }
`
const WebWrapperA = styled(WrapperA)`
  transition: background .1s ease, color .1s ease;

  &:hover, &:focus {
    background: #${p => p.secondary ? '594b6b' : p.white ? 'fff' : 'ff9187'};
    outline: none;
  }
`

export default ({ label, icon: Icon, ...props }) => {
  const Element = props.type ? WebWrapper : WebWrapperA

  return (
    <Element
      {...props}
      href={props.href || 'javascript:void(0);'}
    >
      {Icon && <Icon style={{ marginRight: 10, flex: 'none' }} />}
      <Text white={props.white} loading={props.loading}>{label}</Text>
      {props.loading && <Loading><Loader white size={18} /></Loading>}
    </Element>
  )
}
