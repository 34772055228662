import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import { Label, TextInput, Span, ViewSpan, Small } from '../native-dom'
import { webOnly } from '../responsive'

const Wrapper = styled(Label)`
  flex-direction: column;
`
const InputWrapper = styled(ViewSpan)`
  position: relative;
  flex-direction: row;
`
const LabelSpan = styled(Span)`
  color: ${p => p.invalid ? '#ff6c5f' : '#b2aeb7'};
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  ${webOnly('display:block;')}
`
const Input = styled(TextInput)`
  background: white;
  border: 1px solid ${p => p.invalid ? '#ff6c5f' : '#ebebeb'};
  border-radius: 4px;
  color: #40364d;
  width: 100%;
  font-size: 14px;
  padding: 10px 15px 11px;

  ${p => p.hasFocus && 'border-color: #b2aeb7; box-shadow: 0 4px 8px #eef0f5;'}
  ${p => p.type === 'textarea' && 'padding: 20px;'}
  ${p => p.type === 'textarea' && webOnly('resize: none;')}
  ${webOnly('@media (-moz-touch-enabled:1), (pointer:coarse) { font-size: 16px }')}
  ${webOnly('&:focus { outline: none; }')}
  ${webOnly('::-webkit-input-placeholder { color: rgba(64, 54, 77, 0.6); }')}
  ${webOnly('::-moz-placeholder { color: rgba(64, 54, 77, 0.6); }')}
  ${webOnly(':-ms-input-placeholder { color: rgba(64, 54, 77, 0.6); }')}
`
const Fix = styled(Span)`
  width: 100%;
  font-size: 14px;
  border: 1px solid transparent;
  padding: 10px 15px 11px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  ${webOnly('pointer-events: none;')}
  ${p => !p.visible && 'opacity: 0;'}
  ${webOnly('@media (-moz-touch-enabled:1), (pointer:coarse) { font-size: 16px }')}
`
const Prefix = styled(Fix)`
  padding-right: 0;
  width: auto;
  color: rgba(64, 54, 77, 0.6);
`
const Suffix = styled(Fix)`
  color: transparent;
  flex-direction: row;
`
const SuffixSmall = styled(Small)`
  font-size: 14px;
  color: rgba(64, 54, 77, 0.6);
  ${webOnly('@media (-moz-touch-enabled:1), (pointer:coarse) { font-size: 16px }')}
`
const Required = styled(Span)`color: #ff6c5f; ${webOnly('display:inline;')}`

export default ({
  type,
  label,
  prefix,
  suffix,
  value,
  onChangeText,
  visuallyRequired,
  valueToUseForRequired,
  className,
  showInvalid,
  onFocus,
  onBlur,
  ...props
}) => {
  const [hasFocus, setHasFocus] = useState(false)
  const [stateValue, setValue] = useState('')
  const [paddingLeft, setPaddingLeft] = useState(undefined)
  const prefixRef = useRef(null)
  const showRequired = (visuallyRequired && !(valueToUseForRequired != null ? valueToUseForRequired : value))
  const __value = valueToUseForRequired != null ? valueToUseForRequired : value
  const invalid = showInvalid && (
    !__value ||
    (props.pattern ? !(new RegExp('^' + props.pattern + '$').test(__value)) : false) ||
    (props.max ? parseFloat(__value) > parseFloat(props.max) : false) ||
    (props.min ? parseFloat(__value) < parseFloat(props.min) : false)
  )

  useEffect(() => { value != null && setValue(value) }, [value])

  useLayoutEffect(() => {
    if (prefix && prefixRef.current) {
      if (!stateValue) {
        if (paddingLeft !== undefined) setPaddingLeft(undefined)
        return
      }

      if (prefixRef.current.measure) {
        prefixRef.current.measure((x, y, width) => {
          if (width !== paddingLeft) setPaddingLeft(width)
        })
      } else {
        const width = prefixRef.current.offsetWidth
        if (width !== paddingLeft) setPaddingLeft(width)
      }
    }
  }, [stateValue])

  return (
    <Wrapper className={className}>
      {label && <LabelSpan invalid={invalid}>{showRequired && <Required>* </Required>}{label}</LabelSpan>}
      <InputWrapper>
        {prefix && <Prefix visible={!!stateValue} ref={prefixRef} pointerEvents='none'>{prefix}</Prefix>}
        <Input
          type={type || 'text'}
          value={stateValue}
          invalid={invalid}
          hasFocus={hasFocus}
          onChangeText={value => {
            if (props.maxlength && value && value.length > parseInt(props.maxlength)) {
              value = value.substr(0, props.maxlength)
            }

            setValue(value)
            if (onChangeText) onChangeText(value)
          }}
          style={{ paddingLeft: paddingLeft == null ? (type === 'textarea' ? 20 : 15) : paddingLeft }}
          onFocus={e => {
            if (onFocus) onFocus(e)
            setHasFocus(true)
          }}
          onBlur={e => {
            if (onBlur) onBlur(e)
            setHasFocus(false)
          }}
          {...props}
        />
        {suffix && <Suffix visible={!!stateValue} pointerEvents='none'>{stateValue}<SuffixSmall>{suffix}</SuffixSmall></Suffix>}
      </InputWrapper>
    </Wrapper>
  )
}
