import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import { register } from 'timeago.js'
import store from './store'
import Cookies from './cookies'
import isWeb from './isWeb'
import { getUserSelections, houseVisible } from './helpers/selections'
import { storeSelections } from './helpers/signup'
import { watchMatches, cancelWatchMatches } from './matches'

export default () => {
  register('swedish', (number, index) => [
    ['precis nu', 'precis nu'],
    ['för %s sekunder sedan', 'om %s sekunder'],
    ['för 1 minut sedan', 'om 1 minut'],
    ['för %s minuter sedan', 'om %s minuter'],
    ['för 1 timme sedan', 'om 1 timme'],
    ['för %s timmar sedan', 'om %s timmar'],
    ['för 1 dag sedan', 'om 1 dag'],
    ['för %s dagar sedan', 'om %s dagar'],
    ['för 1 vecka sedan', 'om 1 vecka'],
    ['för %s veckor sedan', 'om %s veckor'],
    ['för 1 månad sedan', 'om 1 månad'],
    ['för %s månader sedan', 'om %s månader'],
    ['för 1 år sedan', 'om 1 år'],
    ['för %s år sedan', 'om %s år']
  ][index])

  Cookies.get('isLoggedIn')
    .then(b => store.setState({ user: b ? {} : undefined }))

  firebase.initializeApp({
    apiKey: 'AIzaSyAUdcdnit83RqkUlvv-dY2bfXnqB803-no',
    authDomain: 'bobyte.firebaseapp.com',
    databaseURL: 'https://bobyte.firebaseio.com',
    projectId: 'bobyte',
    storageBucket: 'bobyte.appspot.com',
    messagingSenderId: '329145586871',
    appId: '1:329145586871:web:e03740ed84ba622b'
  })

  if (typeof IndexedDb !== 'undefined') {
    firebase.firestore().enablePersistence()
  }

  if (isWeb()) {
    firebase.auth().getRedirectResult()
      .catch(loginError => {
        store.setState({ loginError })
      })
  }

  firebase.auth().onAuthStateChanged(user => {
    const { allowUpdatingUserState } = store.getState()

    if (user) {
      Cookies.get('hasEverLiked:' + user.uid)
        .then(b => store.setState({ hasEverLiked: b === 'true' }))
    }

    if (user && allowUpdatingUserState) {
      Cookies.get('registerSelections')
        .then(async selectionsJson => {
          let selections

          if (selectionsJson) {
            try { selections = JSON.parse(selectionsJson) } catch (_) {}

            // Store selections from register
            if (selections) {
              await storeSelections(user, selections, true)
              Cookies.remove('registerSelections')
            }
          }

          // TODO: Maybe store user selections in localStorage or something for quicker access, do not fetch each time

          // Show "almost done" if selections is not enough for house visible
          selections = await getUserSelections(user)
          if (!houseVisible(selections)) store.setState({ almostDoneVisible: true })
        })
    }

    Cookies.remove('redirectedToLogin')

    if (user) {
      Cookies.set('isLoggedIn', 'true')
      if (allowUpdatingUserState) store.setState({ user })
      watchMatches(user)
    } else {
      Cookies.remove('isLoggedIn')
      if (allowUpdatingUserState) store.setState({ user, selections: null, almostDoneVisible: false, filters: undefined, hasGotFirstMatchSnapshot: false })
      cancelWatchMatches()
    }
  })
}
