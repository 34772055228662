/* global fetch */
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Form, Div, H2, Button, A } from '../native-dom'
import ActionButton from './ActionButton'
import Input from './Input'
import r, { webOnly } from '../responsive'
import FeedbackIcon from '../images/feedback.svg'
import CloseIcon from '../images/close.svg'

const Wrapper = styled(Div)`
  position: fixed;
  z-index: 10;
  ${r('mobile', 'bottom: 75px; left: 15px;', 'bottom: 40px; left: 40px;')}
`
const Opener = styled(Button)`
  background: white;
  box-shadow: 0 2px 8px rgba(64, 54, 77, 0.2);
  border-radius: 20px;
  padding: 8px;
`
const FeedbackIconBlock = styled(FeedbackIcon)`
  ${webOnly('display: block;')}
`
const Modal = styled(Form)`
  position: absolute;
  left: 0;
  bottom: 50px;
  background: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 0 45px rgba(64, 54, 77, 0.2);
  ${webOnly('width: calc(100vw - 30px);')}
  max-width: 375px;
  ${p => !p.visible && 'transform: translateY(20px); opacity: 0; visibility: hidden;'}
  ${webOnly('transition: transform .3s ease, opacity .3s ease, visibility .3s ease; pointer-events: auto;')}
`
const Title = styled(H2)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
  color: #2f263a;
`
const CloseButton = styled(A)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #d3d6e1;
  position: absolute;
  top: 15px;
  right: 15px;
  align-items: center;
  justify-content: center;
  z-index: 1;
`
const SubmitButton = styled(ActionButton)`margin-top: 20px;`

export default () => {
  const inputRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [sending, setSending] = useState(false)
  const [sendingMessage, setSendingMessage] = useState('')
  const [message, setMessage] = useState('')

  const onSubmit = (ev) => {
    if (ev) ev.preventDefault()
    if (!message) return

    // Remove focus from input or submit button
    if (document.activeElement) {
      document.activeElement.blur()
    }

    setSending(true)

    fetch('https://europe-west1-bobyte.cloudfunctions.net/submitFeedback', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ message }),
      method: 'POST'
    })
      .then(() => {
        setSending(false)
        setSendingMessage('Skickat!')

        setTimeout(() => {
          setOpen(false)

          setTimeout(() => {
            setMessage('')
            setSendingMessage('')
          }, 300)
        }, 1000)
      })
      .catch(() => {
        setSending(false)
        setSendingMessage('Lyckades inte skicka...')
      })
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const input = inputRef.current.base.querySelector('textarea')
        if (input) input.focus()
      }, 100)
    }
  }, [open])

  // close on esc
  if (typeof window !== 'undefined' && typeof window.addEventListener !== 'undefined') {
    useEffect(() => {
      if (open) {
        const onKeyDown = e => {
          if (e.key === 'Escape') setOpen(false)
        }
        window.addEventListener('keydown', onKeyDown)
        return () => window.removeEventListener('keydown', onKeyDown)
      }
    }, [open])
  }

  return (
    <Wrapper>
      <Opener onClick={() => setOpen(!open)} title='Skicka feedback'>
        <FeedbackIconBlock width={24} height={24} />
      </Opener>
      <Modal visible={open} onSubmit={onSubmit}>
        <CloseButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </CloseButton>
        <Title>Skicka din feedback!</Title>
        <Input
          type='textarea'
          rows={5}
          value={message}
          onChangeText={setMessage}
          disabled={sending || sendingMessage === 'Skickat!'}
          ref={inputRef}
          placeholder='Buggar, idéer eller oklarheter...'
        />
        <SubmitButton
          label={sending ? 'Skickar...' : (sendingMessage || 'Skicka')}
          type='submit'
          loading={sending}
          disabled={!message}
        />
      </Modal>
    </Wrapper>
  )
}
