import Match, { Link as MatchLink } from 'preact-router/match'
import styled, { keyframes } from 'styled-components'
import { connect } from 'unistore/react'
import { isAnyMatchUnread } from '../matches'
import LogoIcon from '../images/logo.svg'
import BackIcon from '../images/back-button.svg'
import r from '../responsive'

const showBack = keyframes`
  from { width: 0; margin-left: 0; margin-right: 0 }
  to { margin-left: -5px; margin-right: 15px; width: 20px }
`

const showBackButton = keyframes`
  from { transform: translateX(-5px); opacity: 0 }
  to { transform: none; opacity: 1 }
`

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`

const Wrapper = styled.div`
  flex: none;

  ${r('mobile', 'transition: height .3s .3s ease; height: 58px; height: calc(58px + env(safe-area-inset-top))', '')}
  ${p => p.hiddenOnMobile && r('mobile', 'height: 0', '')}
`
const Inner = styled.div`
  background: ${p => p.gray ? '#f7f8fc' : 'white'};
  ${r('stack', 'padding: 20px 0', 'padding: 20px 30px')};
  ${r('mobile', 'padding: 15px 0; padding-top: calc(15px + env(safe-area-inset-top))', '')};
  position: relative;
  z-index: 20;
  transition: background .3s ease;
  ${r('mobile', 'box-shadow: 0 8px 28px rgba(0, 0, 0, 0.04); position: fixed; top: 0; left: 0; right: 0; transition: transform .3s ease, opacity .3s ease, visibility .3s ease;', '')}
  ${p => p.hiddenOnMobile && r('mobile', 'transform: translateY(-50%); opacity: 0; visibility: hidden;', '')}
`
const Logo = styled(LogoIcon)`
  ${r('stack', 'width: 117px; height: 28px;', 'width: 146px; height: 35px;')}
`
const Regular = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  align-items: center;
  ${r('stack', 'flex-direction: column', 'flex-direction: row')};
  ${r('mobile', 'flex-direction: row; justify-content: center; padding: 0 15px;')};
  ${r('stack', 'min-height: auto', 'min-height: 40px')};
  ${p => p.hiddenOnMobile && r('mobile', 'display: none', '')}
`
const Mobile = styled.div`
  ${r('mobile', 'display: flex', 'display: none')}
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  padding-top: env(safe-area-inset-top);
`
const Title = styled.h1`
  display: block;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Back = styled.span`
  display: inline-block;
  margin-left: -5px;
  margin-right: 15px;
  animation: ${showBack} .3s ease;
  width: 20px;
  flex: none;

  button {
    width: 20px;
    height: 20px;
    display: inline-block;
    animation: ${showBackButton} .3s ease;
  }
`
const Links = styled.div`
  ${r('stack', '', 'margin-left: auto')};
  ${r('stack', 'margin-top: 20px')};
  ${p => !p.showOnMobile && r('mobile', 'display: none')}
  ${r('mobile', 'margin-left: auto; margin-top: 0;')}
  font-size: 14px;
  flex-direction: row;
  align-items: center;
`
const Link = styled(MatchLink)`
  color: ${p => p.orange ? '#ff6c5f' : 'inherit'};
  ${r('stack', 'margin: 0 10px', 'margin: 0 0 0 50px')};
  cursor: pointer;
  position: relative;
  transition: color .3s ease, background .3s ease;

  ${p => p.orange ? 'text-decoration: underline; text-decoration-color: rgba(255, 108, 55, 0.1);' : 'text-decoration: none;'}

  &.active { color: #ff6c5f; }

  &:after {
    position: absolute;
    top: -5px;
    right: -8px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #ff6c5f;
    animation: ${fadeIn} .5s ease;
  }

  ${p => p.hasNotification && '&:after { content: "" }'}
`
const Profile = styled(Link)`
  background: ${p => p.gray ? 'white' : '#f7f8fc'};
  padding: 7px 20px;
  border-radius: 19px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`
const Photo = styled.figure`
  font-size: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  &:before, > img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
    background: rgba(0, 0, 0, 0.03);
    object-fit: cover;
  }

  ${p => !p.hasPhoto && '&:before { content: "" }'}

  &:after {
    content: '';
    width: 1px;
    height: 16px;
    background: #dfe3ee;
    margin: 0 10px;
    display: inline-block;
  }
`

const Header = ({ gray, user, matches, hiddenOnMobile, title, onBack }) => (
  <Wrapper gray={gray} hiddenOnMobile={hiddenOnMobile}>
    <Inner gray={gray} hiddenOnMobile={hiddenOnMobile}>
      {title && (
        <Mobile>
          {onBack && (
            <Back>
              <button type='button' onClick={onBack}>
                <BackIcon />
              </button>
            </Back>
          )}
          <Title>{title}</Title>
        </Mobile>
      )}
      <Regular hiddenOnMobile={!!title}>
        <a href='/' aria-label='Gå till startsida'><Logo /></a>
        {
          user
            ? (
              <Links>
                <Link activeClassName='active' href='/'>Utforska</Link>
                <Link
                  activeClassName='active'
                  hasNotification={isAnyMatchUnread(matches)}
                  href='/matches'
                >Matchningar</Link>
                <Profile activeClassName='active' href='/me' gray={gray}>
                  <Photo hasPhoto={user.photoURL}>
                    {user.photoURL && <img src={user.photoURL} alt='user avatar' />}
                  </Photo>
                  Min profil
                </Profile>
              </Links>
            )
            : (
              <Links showOnMobile>
                <Link activeClassName='active' href='/login' orange>Logga in</Link>
              </Links>
            )
        }
      </Regular>
    </Inner>
  </Wrapper>
)

const getHeaderProps = ({ url, path }, props) => ({
  gray: (
    (path === '/register' && props.registerView === 'preview')
  ),
  hiddenOnMobile: (
    path.indexOf('/place/') === 0 ||
    path.indexOf('/__components') === 0
  ),
  ...(path === '/matches' ? props.headerTitle || {} : {}),
  ...props
})

export default Header

export const ConnectedHeader = connect('user,matches,registerView,headerTitle')(props => (
  <Match>
    {match => <Header {...getHeaderProps(match, props)} />}
  </Match>
))
