import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Div } from '../native-dom'

const animateLine = keyframes`
  from { stroke-dashoffset: 300 }
  to { stroke-dashoffset: 100 }
`
const animateInnerLine = keyframes`
  from { stroke-dashoffset: 200 }
  to { stroke-dashoffset: 0 }
`
const rotateLine = keyframes`
  from { transform: rotate(-90deg) }
  to { transform: rotate(630deg) }
`
const showLoader = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`

const Wrapper = styled(Div)`
  ${p => p.waitToShow ? 'opacity: 0' : ''}
  animation: ${showLoader} ${p => p.waitToShow ? '0.3s 0.5s' : '0s'} ease forwards;
`
const Loader = styled.svg`
  transform: translateZ(1px);
`
const Circle = styled.circle`
  stroke: ${p => p.white ? 'white' : '#ff6c5f'};
  stroke-width: 4;
  fill: transparent;
  stroke-dasharray: 100;
  transform-origin: center;
  stroke-linecap: round;
`
const InnerCircle = styled(Circle)`
  animation: ${animateInnerLine} 2.5s ease-in-out infinite, ${rotateLine} 2.5s ease-in-out infinite;
`
const OuterCircle = styled(Circle)`
  animation: ${animateLine} 2.5s ease-in-out infinite, ${rotateLine} 2.5s ease-in-out infinite;
`

export default ({ waitToShow, white, size }) => {
  return (
    <Wrapper waitToShow={waitToShow}>
      <Loader width={size || 32} height={size || 32} viewBox='0 0 32 32'>
        <OuterCircle cx={16} cy={16} r={12} pathLength={100} white={white} />
        <InnerCircle cx={16} cy={16} r={5} pathLength={100} white={white} />
      </Loader>
    </Wrapper>
  )
}
