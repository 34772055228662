import firebase from 'firebase/app'
import store from '../store'

const handleGeoPoints = (selections) => {
  if (selections.geometry) {
    if (selections.geometry.constructor !== firebase.firestore.GeoPoint) {
      selections.geometry = new firebase.firestore.GeoPoint(
        selections.geometry.latitude,
        selections.geometry.longitude
      )
    }
  }

  return selections
}

export const storeSelections = (user, selections, merge) => {
  handleGeoPoints(selections)

  selections.user = { name: user.displayName, photo: user.photoURL }

  return firebase.firestore()
    .collection('user-selections')
    .doc(user.uid)
    .set(selections, { merge })
    .then(() => store.setState({ selections }))
}
