export default {
  title: 'Cookies',
  text: `# Cookies

## Vad är cookies och hur fungerar de?

En cookie är en liten textfil som webbplatsen du besöker begär att få spara på din dator. Cookies är en vanlig företeelse och används på många webbplatser. Informationen i cookien gör det t.ex. möjligt att följa kunders surfande kring en webbsida.

## Vad använder vi cookies till?

Vi använder två typer av cookies. Den ena typen sparar en fil under en längre tid på din dator. Den används till exempel för att se hur ofta kunder besöker vår webbplats. I de cookies vi använder sparas ingen personlig information och/eller information relaterad till vilka sidor du besöker.

Den andra typen av cookies kallas sessionscookies. Under tiden du är inne och surfar på en sida, lagras den här cookien temporärt i din dator. En sessionscookie försvinner när du stänger din webbläsare. Den används exempelvis till att hålla reda på att vissa funktioner inte upprepar sig i onödan.

Om du inte accepterar cookies kan du inte använda vår webbapp fullt ut.`
}
