import styled from 'styled-components'
import { Link } from 'preact-router/match'
import { connect } from 'unistore/react'
import r from '../responsive'
import HomeIcon from '../images/home.svg'
import HomeActiveIcon from '../images/home-active.svg'
import MatchesIcon from '../images/matches.svg'
import MatchesActiveIcon from '../images/matches-active.svg'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: white;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -8px 28px rgba(0, 0, 0, 0.04);
  padding: 14px 10px;
  padding-bottom: calc(14px + env(safe-area-inset-bottom));
  transition: opacity .3s ease, visibility .3s ease, transform .3s ease;

  ${p => p.hide && 'opacity: 0; visibility: hidden; transform: translateY(50%);'}
  ${r('mobile', '', 'display: none')}
`
const _Link = styled(Link)`
  display: inline-block;
  flex: none;
  margin: 0 30px;
  position: relative;
`
const IconLink = styled(_Link)`
  .before, .after {
    width: 100%;
    height: 100%;
    transition: opacity .3s ease;
    position: absolute;
    top: 0;
    left: 0;
  }
  .after { opacity: 0 }
  &.active .before { opacity: 0 }
  &.active .after { opacity: 1 }
`
const Home = styled(IconLink)`
  width: 26px;
  height: 26px;
`
const Matches = styled(IconLink)`
  width: 26px;
  height: 24px;
`
const Profile = styled(_Link)`
  width: 30px;
  height: 30px;
  font-size: 0;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    border: 3px solid #ff6c5f;
    opacity: 0;
    transition: opacity .3s ease;
  }

  &:before, > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    display: inline-block;
    background: rgba(0, 0, 0, 0.1);
  }

  ${p => !p.hasPhoto && '&:before { content: "" }'}

  > img { box-shadow: 0 2px 15px rgba(0, 0, 0, .1) }

  &.active:after { opacity: 1 }
`

const Footer = ({ user, hide }) => (
  <Wrapper hide={hide}>
    <Home
      activeClassName='active'
      href='/'
    >
      <HomeIcon className='before' />
      <HomeActiveIcon className='after' />
    </Home>
    <Matches
      activeClassName='active'
      href='/matches'
    >
      <MatchesIcon className='before' />
      <MatchesActiveIcon className='after' />
    </Matches>
    <Profile
      hasPhoto={user && user.photoURL}
      activeClassName='active'
      href='/me'
    >
      {user && user.photoURL && <img src={user.photoURL} alt='user avatar' />}
    </Profile>
  </Wrapper>
)

export default Footer

export const ConnectedFooter = connect('user')(props => (
  <Footer {...props} hide={!props.user} />
))
