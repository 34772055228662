import styled from 'styled-components'
import { useState, useEffect } from 'react'
import Cookies from '../cookies'

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  pointer-events: none;
  z-index: 11;
  transition: transform .3s ease, opacity .3s ease, visibility .3s ease;
  ${p => !p.visible && 'transform: translateY(20px); opacity: 0; visibility: hidden;'}
`
const Bar = styled.div`
  border-radius: 20px;
  background: white;
  box-shadow: 0 0 45px rgba(65, 54, 77, 0.2);
  font-size: 14px;
  line-height: 14px;
  flex-direction: row;
  margin: auto;
  pointer-events: ${p => p.visible ? 'auto' : 'none'};
  align-items: center;
  padding: 13px 20px;
`
const Text = styled.span`
  color: #40364d;
`
const Link = styled.a`
  text-decoration: underline;
  margin-left: 20px;
  white-space: nowrap;
`
const Accept = styled(Link)`
  color: #ff6c5f;
`
const More = styled(Link)`
  color: #9f9ba6;
`

export default () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    Cookies.get('acceptedCookies')
      .then(accepted => setVisible(accepted !== 'true'))
  }, [])

  const onAccept = (ev) => {
    ev.preventDefault()
    Cookies.set('acceptedCookies', true)
    setVisible(false)
  }

  return (
    <Wrapper visible={visible}>
      <Bar visible={visible}>
        <Text>🍪 Vi använder cookies. Genom att fortsätta ger du oss ditt medgivande att använda det.</Text>
        <More href='/cookies'>Läs mer</More>
        <Accept href='#' onClick={onAccept}>Acceptera</Accept>
      </Bar>
    </Wrapper>
  )
}
