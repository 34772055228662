import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Div, H2, P, Button, A } from '../native-dom'
import ActionButton from './ActionButton'
import r, { webOnly, nativeOnly } from '../responsive'
import CloseIcon from '../images/close.svg'
import Loader from './Loader'

const NoBackdrop = styled(Div)``
const Backdrop = styled(Div)`
  background: rgba(50, 48, 54, 0.5);
  ${webOnly('position: fixed;')}
  ${nativeOnly('position: absolute;')}
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${p => p.doNotBlockHeader ? '19' : '30'};
  align-items: center;
  padding: 40px 20px;
  ${webOnly(r('stack', 'justify-content: flex-end', 'justify-content: center;'))}
  ${nativeOnly('justify-content: flex-end;')}
  ${webOnly('transition: opacity .2s ease, visibility .2s ease;')}
  ${p => !p.visible && 'opacity: 0;'}
  ${p => !p.visible && webOnly('visibility: hidden;')}
  ${p => !p.visible && nativeOnly('display: none;')}
  ${p => p.inBottomRight && r('stack', '', webOnly('pointer-events: none; background: transparent; align-items: flex-end; justify-content: flex-end; padding: 40px;'))}
`
const BackdropClose = styled(Button)`
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${webOnly('cursor: default;')}
`
const CloseButton = styled(A)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #d3d6e1;
  position: absolute;
  top: 15px;
  right: 15px;
  align-items: center;
  justify-content: center;
  z-index: 1;
`
const Modal = styled(Div)`
  position: relative;
  background: white;
  border-radius: 20px;
  ${p => p.large ? r('mobile', 'padding: 20px', 'padding: 40px 70px') : 'padding: 20px;'}
  box-shadow: 0 0 45px rgba(64, 54, 77, 0.2);
  width: 100%;
  max-width: ${p => p.large ? '550px' : '335px'};
  ${p => !p.visible && 'transform: translateY(20px);'}
  ${webOnly('transition: transform .3s ease; pointer-events: auto;')}
`
const Title = styled(H2)`
  font-weight: bold;
  font-size: ${p => p.large ? '30px' : '24px'};
  line-height: 32px;
  margin-bottom: ${p => p.large ? '20px' : '8px'};
  color: #2f263a;
  ${p => p.large && 'text-align: center;'}
`
const Text = styled(P)`
  font-size: 16px;
  line-height: 24px;
  color: #40364d;
  ${p => !p.first && 'margin-top: 20px;'}
  ${p => p.large && 'text-align: center;'}
`
const Buttons = styled(Div)`
  margin-top: ${p => p.large ? '40px' : '20px'};
`
const NextButton = styled(ActionButton)`
  ${p => !p.last && 'margin-bottom: 16px;'}
`
const ErrorMessage = styled(P)`
  width: 200px;
  text-align: center;
  color: red;
  font-size: 14px;
  line-height: 18px;
  margin: 30px auto 0;
`
const LoaderWrapper = styled(Div)`margin: 20px auto 0;`

export default ({ visible, large, title, text, buttons, onClose, loading, error, inBottomRight, noBackdrop, imageComponent, doNotBlockHeader }) => {
  // close on esc
  if (typeof window !== 'undefined' && typeof window.addEventListener !== 'undefined') {
    useEffect(() => {
      if (visible) {
        const onKeyDown = e => {
          if (e.key === 'Escape') onClose()
        }
        window.addEventListener('keydown', onKeyDown)
        return () => window.removeEventListener('keydown', onKeyDown)
      }
    }, [visible])
  }
  const BackdropElement = noBackdrop ? NoBackdrop : Backdrop

  return (
    <BackdropElement visible={visible} inBottomRight={inBottomRight} pointerEvents={visible ? 'box-none' : 'none'} doNotBlockHeader={doNotBlockHeader}>
      {!noBackdrop && <BackdropClose onClick={onClose} />}
      <Modal visible={visible || noBackdrop} large={large}>
        {onClose && (
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        )}
        {imageComponent}
        <Title large={large}>{title}</Title>
        {text.split('\n').map((row, i) => <Text large={large} key={i} first={i === 0}>{row}</Text>)}
        {
          loading
            ? <LoaderWrapper><Loader /></LoaderWrapper>
            : (
              <Buttons large>
                {buttons.map((b, i) => <NextButton key={i} last={i === buttons.length - 1} {...b} />)}
              </Buttons>
            )
        }
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Modal>
    </BackdropElement>
  )
}
