import firebase from 'firebase/app'
import store from './store'
import Cookies from './cookies'

let watchedUid
let unlisten

export function watchMatches (user) {
  if (!user || !user.uid) return
  if (user.uid !== watchedUid) cancelWatchMatches()

  watchedUid = user.uid

  const houses = firebase.firestore().collection('user-selections')

  unlisten = firebase.firestore()
    .collection('matches')
    .where('users', 'array-contains', watchedUid)
    .onSnapshot(snapshot => {
      Promise.all(
        snapshot.docs
          .map(async d => {
            const data = d.data()
            const houseId = data.users.find(id => id !== watchedUid)
            // TODO: Cache house data
            const house = (await houses.doc(houseId).get()).data()
            const latestReads = extractLatestReads(data)
            const latestRead = latestReads[watchedUid]

            // On new match, set newMatchAddress to "street"
            if (
              store.getState().hasGotFirstMatchSnapshot &&
              isNew({ latestRead })
            ) {
              store.setState({ newMatchAddress: house.address })
            }

            return {
              id: d.id,
              house: { id: houseId, ...house },
              createdAt: data.createdAt.toDate(),
              latestWrite: data.latestWrite.toDate(),
              latestMessage: data.latestMessage,
              latestRead
            }
          })
      )
        .then(matches => matches.sort((a, b) => b.latestWrite - a.latestWrite))
        // .then(matches => matches.concat(matches).concat(matches).concat(matches).concat(matches).concat(matches).concat(matches).concat(matches).concat(matches).concat(matches).concat(matches))
        .then(matches => store.setState({ matches }))
        .then(() => {
          // Do not show any modals on first load (since they're not new)
          store.setState({ hasGotFirstMatchSnapshot: true })
        })
    })
}

export function cancelWatchMatches () {
  watchedUid = null
  if (unlisten) unlisten()
}

export function extractLatestReads (data) {
  const latestReads = {}
  Object.keys(data).forEach(key => {
    if (key.indexOf('latestReadAt') === 0) {
      latestReads[key.substr('latestReadAt_'.length)] = data[key].toDate()
    }
  })
  return latestReads
}

export function isAnyMatchUnread (matches) {
  if (!matches) return false
  for (const m of matches) {
    if (isNew(m) || isUnread(m)) return true
  }
  return false
}

// Store if user has tried to like something
export function storeHasEverLiked (uid) {
  Cookies.set('hasEverLiked:' + uid, 'true')
  store.setState({ hasEverLiked: true })
}

export const isNew = (m) => (!m.latestRead)
export const isUnread = (m) => (
  m.latestWrite &&
  (m.latestRead ? m.latestRead < m.latestWrite : true)
)

export function houseIsAMatch (houseId, matches) {
  if (!matches) return false

  return !!matches.find(m => m.house.id === houseId)
}

export function hideMatchLocally (houseId) {
  store.setState({
    matches: (store.getState().matches || [])
      .filter(m => m.house.id !== houseId)
  })
}
