import styled from 'styled-components'
import { Span, Button, A, ViewSpan } from '../native-dom'
import { webOnly } from '../responsive'

export const Wrapper = styled(Button)`
  border-radius: 20px;
  background: #${p => p.secondary ? '40364d' : p.white ? 'fff' : 'ff6c5f'};
  font-size: 14px;
  padding: ${p => p.compact ? '8px' : '12px 25px'};
  line-height: 16px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  elevation: 4;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  ${p => p.loading && webOnly('pointer-events: none')}
  ${webOnly('&[disabled] { pointer-events: none; opacity: 0.2; box-shadow: none }')}
`
export const WrapperA = styled(A)`
  border-radius: 20px;
  background: #${p => p.secondary ? '40364d' : p.white ? 'fff' : 'ff6c5f'};
  font-size: 14px;
  padding: ${p => p.compact ? '8px' : '12px 25px'};
  line-height: 16px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  elevation: 4;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  ${p => p.loading && webOnly('pointer-events: none')}
  ${webOnly('&[disabled] { pointer-events: none; opacity: 0.2; box-shadow: none }')}
`

export const Text = styled(Span)`
  flex: 1;
  text-align: center;
  font-weight: 600;
  color: ${p =>
    p.white
      ? (p.loading ? 'rgba(64, 54, 77, 0.2)' : '#40364d')
      : (p.loading ? 'rgba(255, 255, 255, 0.2)' : '#fff')
};
`

export const Loading = styled(ViewSpan)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
`
