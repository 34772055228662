import Loader from './Loader'
import styled from 'styled-components'

const Block = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f7f8fc;
  z-index: 19;
  align-items: center;
  justify-content: center;
  transition: visibility .3s ease, opacity .3s ease;

  ${p => !p.visible && 'visibility: hidden; opacity: 0; transition-duration: .5s; > * { display: none; }'}
`

export default ({ visible }) => (
  <Block visible={visible}>
    <Loader waitToShow />
  </Block>
)
