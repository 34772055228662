const mapWidth = (width) => {
  switch (width) {
    case 'stack': return 900
    case 'mobile': return 414
  }
  return width
}

export default (width, belowStyle, aboveStyle) => (
  (aboveStyle ? `@media (min-width: ${mapWidth(width) + 1}px) { ${aboveStyle}; }` : '') + ' ' +
  (belowStyle ? `@media (max-width: ${mapWidth(width)}px) { ${belowStyle}; }` : '')
)

export const webOnly = (style) => style + ';'
export const nativeOnly = () => ''
