import './index.css'
import styled from 'styled-components'
// import styled, { ServerStyleSheet } from 'styled-components'
// import renderToString from 'preact-render-to-string'
import { useState, useEffect } from 'react'
import { Provider, connect } from 'unistore/react'
import Landing from './routes/Landing'
import Home from './routes/Home'
import Login from './routes/Login'
import Profile from './routes/Profile'
import House from './routes/House'
import Matches from './routes/Matches'
import Register from './routes/Register'
import FourOhFour from './routes/404'
import ContentPage from './routes/Content'
import EmptyPage from './routes/Empty'
import Components from './routes/Components'
import FadingRouter from './components/FadingRouter'
import FadeBlock from './components/FadeBlock'
import Feedback from './components/Feedback'
import { ConnectedHeader } from './components/Header'
import { ConnectedFooter } from './components/Footer'
import ConnectedNewMatchesModal from './components/ConnectedNewMatchesModal'
import CookiesBar from './components/CookiesBar'
import { sendPageView } from './helpers/analytics'
import store from './store'
import init from './init'

init()

let hasSentPageView = false

if (process.env.NODE_ENV !== 'development' && typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
  // eslint-disable-next-line
  navigator.serviceWorker.register(__webpack_public_path__ + 'sw.js')
}

const View = styled.div`
  flex: 1;
  min-height: 100vh;

  @supports (height: -webkit-fill-available) { min-height: stretch; }
  @media all and (display-mode: standalone) { min-height: calc(100vh - 4rem); }
`

let startLoadTimestamp
const MIN_LOAD_MS = 400

const Router = connect('user')(({ user, onStartChange }) => (
  <FadingRouter delay={300} onStartChange={onStartChange}>
    {user ? <Home path='/' /> : <Landing path='/' />}
    <Login path='/login' />
    <Profile path='/me' />
    <Register path='/edit' isEdit />
    <Matches path='/matches' />
    <House path='/place/:id' />
    <ContentPage path='/cookies' content={require('./content/cookies').default} />
    <EmptyPage path='/__empty' />
    <Components path='/__components' />
    <FourOhFour default />
  </FadingRouter>
))
// <Register path='/register' />

const App = ({ styleTags }) => {
  const [loadingRoute, setLoadingRoute] = useState(false)

  const handleRouteChange = () => {
    if (typeof window !== 'undefined') { // only update on user journey
      startLoadTimestamp = Date.now()
      setLoadingRoute(true)
    }
  }

  if (typeof window !== 'undefined') {
    useEffect(() => {
      window.addEventListener('routeloading', () => setLoadingRoute(true))
      window.addEventListener('routeloaded', () => {
        const timestampDiff = Date.now() - startLoadTimestamp
        const timeLeftToLoad = Math.max(50, MIN_LOAD_MS - timestampDiff)
        // Min 50ms to prevent routeloading immediately after routeloaded

        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0)
        }

        setTimeout(() => {
          setLoadingRoute(false)

          // Only send page view on dynamic routes, i.e. not first
          if (hasSentPageView) {
            sendPageView(document.location.pathname)
          }
          hasSentPageView = true
        }, timeLeftToLoad + 50)
      })
    }, [])
  }

  return (
    <Provider store={store}>
      <View>
        {/* styleTags && <div dangerouslySetInnerHTML={{ __html: styleTags }} /> */}
        <ConnectedHeader />
        <ConnectedFooter />
        <CookiesBar />
        <Feedback />
        <FadeBlock visible={loadingRoute} />
        <Router onStartChange={handleRouteChange} />
        <ConnectedNewMatchesModal />
      </View>
    </Provider>
  )
}

// const ServerApp = () => {
//   // https://twitter.com/nikkitaftw/status/930373304401227776
//   // https://twitter.com/nikkitaftw/status/963565544833277952
//   const sheet = new ServerStyleSheet()
//   const html = renderToString(sheet.collectStyles(<App />))
//   const styleTags = sheet.getStyleTags() // or sheet.getStyleElement()
//   // console.log(html)
//   // console.log('STYLE TAGS:', styleTags)
//
//   return <App styleTags={styleTags} />
// }

// if (typeof window === 'undefined') {
//   console.log(document.body)
// } else {
//   let root = document.body.firstElementChild
//   render(<App />, document.body, root)
// }

// export default typeof window === 'undefined' ? ServerApp : App
export default App
