import { Router } from 'preact-router'

const normalizeUrl = (url) => (
  url.replace(/(\/$|\/?#.*$)/, '')
)

export default class FadingRouter extends Router {
  constructor (props) {
    super(props)
    this.state.renderedUrl = this.state.url
  }

  componentWillUpdate (nextProps, nextState) {
    super.componentWillUpdate(nextProps, nextState)

    if (normalizeUrl(nextState.url) !== normalizeUrl(this.state.url)) {
      if (typeof this.props.onStartChange === 'function') {
        this.props.onStartChange()
      }

      setTimeout(() => {
        this.setState({ renderedUrl: nextState.url })
      }, this.props.delay)
    }
  }

  render (props, { renderedUrl }) {
    return super.render(props, { url: renderedUrl })
  }
}
