import firebase from 'firebase/app'
import store from '../store'

export const getUserSelections = async (user) => {
  if (!user) user = store.getState().user
  if (!user) return undefined

  const doc = await firebase.firestore()
    .collection('user-selections')
    .doc(user.uid)
    .get()
  const selections = doc.data()

  // selections.images = selections.images.concat(selections.images)
  // selections.images = selections.images.concat(selections.images)

  store.setState({ selections: selections || null })

  return selections
}

export const houseVisible = (house) => !!(
  house &&
  // house.images && house.images.length &&
  house.address &&
  house.size &&
  house.rooms &&
  // house.floor != null && house.floor !== '' &&
  house.rent != null && house.rent !== '' &&
  house.description
)
