import styled from 'styled-components'
import { connect } from 'unistore/react'
import Modal from './Modal'
import LikeIcon from '../images/small-like.svg'

const LargeLikeModalIcon = styled.div`
  margin: 0 auto 20px;
  display: block;
`

const actions = {
  clearNewMatches: () => ({ newMatchAddress: null })
}

export default connect('newMatchAddress', actions)(({ newMatchAddress, clearNewMatches }) => {
  if (!newMatchAddress) {
    return <div />
  }

  return (
    <Modal
      imageComponent={<LargeLikeModalIcon><LikeIcon width={78} height={54} /></LargeLikeModalIcon>}
      large
      visible
      title='Wohoo, en ny matchning!'
      text={`Du har matchat med ${newMatchAddress}, en chatt har skapats för att ni ska kunna gå vidare med ert eventuella bobyte!`}
      buttons={[
        { label: 'Gå till mina matchningar', secondary: true, href: '/matches', onClick: clearNewMatches }
      ]}
      onClose={clearNewMatches}
    />
  )
})
